import request from '@/request';
const part_url = "/share/cms";
/**
 * 修改状态
 *
 * @param {*} id 编号
 * @param {*} status 有效状态，1有效0无效
 * @returns
 */
function updateStatus(id, status) {
    return request.get(part_url+'/newsCategory/updateStatus', { id, status })
}

/**
 * 根据id查询
 *
 * @param {*} id 编号
 * @returns
 */
function getById(id) {
    return request.get(part_url+'/newsCategory/getById', { id })
}

/**
 * 修改
 *
 * @param {*} id
 * @param {*} parentId 父栏目id
 * @param {*} name 名字
 * @param {*} category 类型 
 * @param {*} linkUrl 跳转地址
 * @param {*} picture 展示小图标
 * @param {*} advImg 广告图
 * @param {*} appShow 是否在app显示
 * @param {*} pcShow 是否在pc显示
 * @param {*} isBottom 是否底部导航
 * @param {*} status 有效状态，1有效0无效
 * @param {*} websiteTitle 网站标题
 * @param {*} websiteKeyword 网站关键字
 * @param {*} websiteDesc 网站描述
 */
function saveOrUpdate(newsCategory) {
    return request.post(part_url+'/newsCategory/saveOrUpdate', {}, newsCategory)
}

/**
 * 删除
 *
 * @param {*} id 编号
 * @returns
 */
function del(id) {
    return request.get(part_url+'/newsCategory/removeById', { id })
}

/**
 * 新闻资讯分类树形结构数据
 * @param {*} containLink 是否包含链接类型分类
 */
function getTree(containLink) {
    return request.get(part_url+'/newsCategory/getTree', { containLink })
}
/**
 * 查询所有分类树形
 * @returns 
 */
function getAllTree() {
    return request.get(part_url+'/newsCategory/getAllTree', {})
}

/**
 * 修改排序
 * @param {*} id 分类id
 * @param {*} type  类型 0 下移 1 上移
 */
function sortCategory(id, type) {
    return request.get(part_url+'/newsCategory/sortCategory', { id, type })
}
/**
 * 查询类型
 */
function getCategoryTypes() {
    return request.get(part_url+'/newsCategory/getCategoryTypes', {})
}

/**
 * 获取父级id
 */
function getParents(id) {
    return request.get(part_url+'/newsCategory/getParents', { id })
}

export default {
    saveOrUpdate,
    updateStatus,
    getById,
    del,
    getAllTree,
    getTree,
    sortCategory,
    getCategoryTypes,
    getParents
}