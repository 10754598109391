<template>
  <div>
    <a-spin :tip="loadingTip" :spinning="isloading">
      <div style="padding: 16px">
        <p-button
          @click="showInfo(0, 0)"
          type="primary"
          name="添加一级分类"
          :roles="['文章-文章分类添加']"
        />
        <a-tree
          :replaceFields="replaceFields"
          :treeData="treeData"
          showLine
          style="margin-top: 16px"
        >
          <a-icon
            slot="switcherIcon"
            type="caret-down"
            style="font-size: 24px"
          />
          <template slot="custom" slot-scope="item">
            <a-icon
              type="check-circle"
              style="margin-right: 2px; color: #52c41a"
              v-if="item.status == 1"
            />
            <a-icon
              type="stop"
              style="margin-right: 2px; color: #ff4d4f"
              v-else
            />
            <span>{{ item.name }}</span>
            <p-button
              name="添加"
              size="small"
              @click="showInfo(0, item.id)"
              type="link"
              :roles="['文章-文章分类添加']"
            />
            <p-button
              name="编辑"
              size="small"
              @click="showInfo(item.id, item.parentId)"
              type="link"
              :roles="['文章-文章分类修改']"
            />
            <p-button
              name="上移"
              size="small"
              type="link"
              @click="sortCategory(item.id, 1)"
              v-if="!item.first"
              :roles="['文章-文章分类修改']"
            />
            <p-button
              name="下移"
              size="small"
              type="link"
              @click="sortCategory(item.id, 0)"
              v-if="!item.last"
              :roles="['文章-文章分类修改']"
            />
            <a-popconfirm
              title="是否确认禁用此分类"
              @confirm="updateStatus(item.id, 0)"
              v-if="item.status == 1"
            >
              <a-icon slot="icon" type="question-circle-o" />
              <p-button
                size="small"
                type="link"
                name="禁用"
                :roles="['文章-文章分类修改状态']"
              />
            </a-popconfirm>
            <a-popconfirm
              title="是否确认启用此分类"
              @confirm="updateStatus(item.id, 1)"
              v-else
            >
              <a-icon slot="icon" type="question-circle-o" />
              <p-button
                size="small"
                type="link"
                name="启用"
                :roles="['文章-文章分类修改状态']"
              />
            </a-popconfirm>
            <a-popconfirm @confirm="delInfo(item.id)">
              <div slot="title">是否确认删除此分类？</div>
              <a-icon slot="icon" type="question-circle-o" />
              <p-button
                name="删除"
                size="small"
                style="color: red"
                type="link"
                :roles="['文章-文章分类删除']"
              />
            </a-popconfirm>
          </template>
        </a-tree>
      </div>
    </a-spin>
    <a-modal
      :title="addedit.title"
      v-model="addedit.visible"
      width="800px"
      :footer="false"
      destroyOnClose
      :maskClosable="false"
    >
      <CategoryInfo @callbackMethod="save" :infos="addedit.info" />
    </a-modal>
  </div>
</template>
<script>
import CategoryInfo from "./CategoryInfo.vue"; //编辑
import category from "@/api/cms/category";
let _this;
export default {
  name: "categoryList",
  data() {
    return {
      replaceFields: { children: "children", title: "name", key: "id" },
      treeData: [],
      isloading: false,
      loadingTip: "加载中...",
      addedit: {
        title: "",
        info: {},
        visible: false,
      },
    };
  },
  components: {
    CategoryInfo,
  },
  mounted: function () {
    _this = this;
    _this.getAllTree();
  },
  methods: {
    getAllTree() {
      category.getAllTree().then((result) => {
        if (result.errorCode == _this.$commons.RespCode.success) {
          _this.changeTreeData(result.data);
          _this.treeData = result.data;
        } else {
          _this.$message.error(result.errorMsg);
        }
      });
    },
    changeTreeData(datas) {
      for (let key in datas) {
        datas[key].scopedSlots = { title: "custom" };
        if (key == 0) {
          datas[key].first = true;
        }
        if (key == datas.length - 1) {
          datas[key].last = true;
        }
        if (datas[key].children && datas[key].children.length > 0) {
          _this.changeTreeData(datas[key].children);
        }
      }
    },
    showInfo(id, parentId) {
      this.addedit.visible = true;
      this.addedit.title = id == 0 ? "新增" : "编辑";
      this.addedit.info = { id, parentId };
    },
    /**禁用启用的方法 */
    updateStatus(ids, status) {
      _this.isloading = true;
      category
        .updateStatus(ids, status)
        .then((res) => {
          if (res.errorCode == _this.$commons.RespCode.success) {
            _this.$message.success(res.errorMsg);
            _this.getAllTree();
          } else {
            _this.$message.error(res.errorMsg);
          }
          _this.isloading = false;
        })
        .catch(() => {
          _this.isloading = false;
        });
    },
    /**删除 */
    delInfo(id) {
      _this.isloading = true;
      category
        .del(id)
        .then((res) => {
          if (res.errorCode == _this.$commons.RespCode.success) {
            _this.$message.success(res.errorMsg);
            _this.getAllTree();
          } else {
            _this.$message.error(res.errorMsg);
          }
          _this.isloading = false;
        })
        .catch(() => {
          _this.isloading = false;
        });
    },
    sortCategory(id, type) {
      _this.isloading = true;
      category
        .sortCategory(id, type)
        .then((res) => {
          if (res.errorCode == _this.$commons.RespCode.success) {
            _this.getAllTree();
            _this.$message.success("操作成功");
          } else {
            _this.$message.error(res.errorMsg);
          }
          _this.isloading = false;
        })
        .catch(() => {
          _this.isloading = false;
        });
    },
    //子组件传值
    save() {
      this.addedit.visible = false;
      this.getAllTree();
    },
  },
};
</script>

<style>
</style>

