<template>
  <a-spin :tip="loadingTip" :spinning="loading">
    <div>
      <a-form-model
        ref="newsCategoryForm"
        :model="form"
        :rules="rules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
      >
        <a-form-model-item label="上级分类">
          <a-cascader
            placeholder="请选择"
            :options="treeData"
            changeOnSelect
            allowClear
            v-model="form.parentId"
            :fieldNames="{ label: 'name', value: 'id', children: 'children' }"
          />
        </a-form-model-item>
        <a-form-model-item label="分类名称">
          <a-input
            placeholder="请输入"
            v-model="form.name"
            :maxLength="15"
          ></a-input>
        </a-form-model-item>
        <a-form-model-item label="分类类型">
          <a-radio-group v-model="form.category">
            <template v-for="type in categoryList">
              <a-radio :value="type.type" :key="type.type">{{
                type.name
              }}</a-radio>
            </template>
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item label="链接地址" v-if="form.category == 3">
          <a-input placeholder="请输入" v-model="form.linkUrl"></a-input>
        </a-form-model-item>
        <a-row>
          <a-col :span="10">
            <a-form-model-item
              label="展示封面"
              :label-col="{ span: 12 }"
              :wrapper-col="{ span: 8 }"
            >
              <a-upload
                accept="image/png, image/jpeg"
                listType="picture-card"
                :action="uploadFileUrl"
                :fileList="pictureList"
                :beforeUpload="beforeUpload"
                @preview="handlePreview"
                @change="handlePictureChange"
              >
                <div v-if="pictureList.length == 0">
                  <a-icon type="plus" />
                  <div class="ant-upload-text">Upload</div>
                </div>
              </a-upload>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item
              label="分类广告图"
              :label-col="{ span: 10 }"
              :wrapper-col="{ span: 8 }"
            >
              <a-upload
                accept="image/png, image/jpeg"
                listType="picture-card"
                :action="uploadFileUrl"
                :fileList="advImgList"
                :beforeUpload="beforeUpload"
                @preview="handlePreview"
                @change="handleAdvImgChange"
              >
                <div v-if="advImgList.length == 0">
                  <a-icon type="plus" />
                  <div class="ant-upload-text">Upload</div>
                </div>
              </a-upload>
            </a-form-model-item>
          </a-col>
          <a-modal
            :visible="previewVisible"
            :footer="null"
            @cancel="handleCancel"
          >
            <img alt="example" style="width: 100%" :src="previewImage" />
          </a-modal>
        </a-row>
        <a-row>
          <a-col :span="10">
            <!-- <a-form-model-item
              label="H5广告图"
              :label-col="{ span: 12 }"
              :wrapper-col="{ span: 8 }"
            >
              <a-upload
                accept="image/png,image/jpeg"
                listType="picture-card"
                :action="uploadFileUrl"
                :fileList="h5PictureList"
                :beforeUpload="beforeUpload"
                @preview="handlePreview"
                @change="handleH5PictureChange"
              >
                <div v-if="h5PictureList.length == 0">
                  <a-icon type="plus" />
                  <div class="ant-upload-text">Upload</div>
                </div>
              </a-upload>
                        </a-form-model-item>-->
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="10">
            <a-form-model-item
              label="是否在PC显示"
              :label-col="{ span: 12 }"
              :wrapper-col="{ span: 8 }"
            >
              <a-switch
                v-model="form.pcShow"
                checked-children="是"
                un-checked-children="否"
              />
            </a-form-model-item>
          </a-col>
          <!--          <a-col :span="12">-->
          <!--            <a-form-model-item-->
          <!--              label="是否在APP显示"-->
          <!--              :label-col="{ span: 10 }"-->
          <!--              :wrapper-col="{ span: 8 }"-->
          <!--            >-->
          <!--              <a-switch-->
          <!--                v-model="form.appShow"-->
          <!--                checked-children="是"-->
          <!--                un-checked-children="否"-->
          <!--              />-->
          <!--            </a-form-model-item>-->
          <!--          </a-col>-->
        </a-row>
        <a-row>
          <a-col :span="10">
            <a-form-model-item
              label="是否PC底部导航"
              :label-col="{ span: 12 }"
              :wrapper-col="{ span: 8 }"
            >
              <a-switch
                v-model="form.isBottom"
                checked-children="是"
                un-checked-children="否"
              />
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item
              label="状态"
              :label-col="{ span: 10 }"
              :wrapper-col="{ span: 8 }"
            >
              <a-switch
                v-model="form.status"
                checked-children="启用"
                un-checked-children="禁用"
              />
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-form-model-item label="网站标题">
          <a-input placeholder="请输入" v-model="form.websiteTitle"></a-input>
        </a-form-model-item>
        <a-form-model-item label="网站关键字">
          <a-input placeholder="请输入" v-model="form.websiteKeyword"></a-input>
        </a-form-model-item>
        <a-form-model-item label="网站描述">
          <a-input placeholder="请输入" v-model="form.websiteDesc"></a-input>
        </a-form-model-item>
        <a-form-model-item label="允许操作的角色" >
             <a-select v-model="form.roles" mode="multiple" placeholder="请选择">
                <template v-for="tag in roleAll">
                  <a-select-option :value="tag" :key="tag">
                    {{ tag }}
                  </a-select-option>
                </template>
              </a-select>
        </a-form-model-item>
        <a-form-model-item :wrapper-col="{ span: 10, offset: 5 }">
          <a-button type="primary" @click="handleSubmit">保存</a-button>
          <a-button type="default" @click="cancelClick" class="cancelBtn"
            >取消</a-button
          >
        </a-form-model-item>
      </a-form-model>
    </div>
  </a-spin>
</template>

<script>
import request from "@/request";
import category from "@/api/cms/category";
import roleApi from '@/api/system/systemUser'
let that;
export default {
  name: "CategoryInfo",
  props: {
    infos: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      roleAll: [],
      labelCol: { span: 5 },
      wrapperCol: { span: 17 },
      categoryList: [],
      treeData: [],
      form: {
        parentId: ["0"],
        category: 1,
        status: true,
        pcShow: true,
        appShow: true,
        isBottom: false,
        roles:[]
      },
      rules: {
        parentId: [
          {
            required: true,
            message: "请选择上级分类",
            trigger: "change",
          },
        ],
        name: [
          {
            required: true,
            message: "请输入分类名称",
            trigger: "blur",
          },
        ],
        category: [
          {
            required: true,
            message: "请选择分类类型",
            trigger: "change",
          },
        ],
      },
      loading: false,
      loadingTip: "加载中...",
      previewVisible: false,
      previewImage: "",
      pictureList: [],
      advImgList: [],
      h5PictureList: [],
      uploadFileUrl: "",
    };
  },
  mounted() {
    that = this;
    that.uploadFileUrl = request.getUploadUrl('cms');
    that.loading = true;
    that.getAllTree();
    that.getCategoryTypes();
    that.getRoleAll();
    if (that.infos.id != 0) {
      that.getInfo();
    }
  },
  methods: {
    getRoleAll(){
      roleApi
        .getRolesAll()
        .then((res) => {
          if (res.errorCode == that.$commons.RespCode.success) {
            that.roleAll = res.data;
          } else {
            that.$message.error(res.errorMsg);
          }
         
          that.isloading = false;
        })
        .catch(() => {
          that.isloading = false;
        });
    },
    getCategoryTypes() {
      category.getCategoryTypes().then((result) => {
        if (result.errorCode == that.$commons.RespCode.success) {
          that.categoryList = result.data;
        } else {
          that.$message.error(result.errorMsg);
        }
      });
    },
    getAllTree() {
      category.getAllTree().then((result) => {
        if (result.errorCode == that.$commons.RespCode.success) {
          let treeData = [
            {
              name: "根目录",
              parentId: "0",
              id: "0",
              children: result.data,
            },
          ];
          that.treeData = treeData;
          if (that.infos.id == 0) {
            that.getParent();
          }
        } else {
          that.$message.error(result.errorMsg);
        }
      });
    },
    getParent() {
      category
        .getParents(that.infos.parentId)
        .then((res) => {
          if (res.errorCode == that.$commons.RespCode.success) {
            that.form.parentId = that.form.parentId.concat(res.data);
          } else {
            that.$message.error(res.errorMsg);
          }
          that.loading = false;
        })
        .catch(() => {
          that.loading = false;
        });
    },
    getInfo() {
      that.loading = true;
      category
        .getById(that.infos.id)
        .then((res) => {
          if (res.errorCode == that.$commons.RespCode.success) {
            if (res.data.picture) {
              that.pictureList.push({
                name: "picture",
                response: {
                  data: {
                    name: res.data.picture,
                    path: res.data.picture,
                  },
                },
                uid: "picture",
                thumbUrl: res.data.picture,
              });
            }
            if (res.data.advImg) {
              that.advImgList.push({
                name: "advImg",
                response: {
                  data: {
                    name: res.data.advImg,
                    path: res.data.advImg,
                  },
                },
                uid: "advImg",
                thumbUrl: res.data.advImg,
              });
            }
            if (res.data.h5Picture) {
              that.h5PictureList.push({
                name: "h5Picture",
                response: {
                  data: {
                    name: res.data.h5Picture,
                    path: res.data.h5Picture,
                  },
                },
                uid: "h5Picture",
                thumbUrl: res.data.h5Picture,
              });
            }
            if(res.data.roles){
              res.data.roles=res.data.roles.split(",");
            }
            that.form = { ...res.data, parentId: ["0"] };
            that.form.appShow = that.$util.int2Boolean(res.data.appShow);
            that.form.pcShow = that.$util.int2Boolean(res.data.pcShow);
            that.form.isBottom = that.$util.int2Boolean(res.data.isBottom);
            that.form.status = that.$util.int2Boolean(res.data.status);
            that.getParent();
          } else {
            that.$message.error(res.errorMsg);
          }
          that.loading = false;
        })
        .catch(() => {
          that.loading = false;
        });
    },
    handleSubmit() {
      that.$refs.newsCategoryForm.validate((valid) => {
        if (valid) {
          let formData = { ...that.form };
          formData.appShow = this.$util.boolean2Int(that.form.appShow);
          formData.pcShow = this.$util.boolean2Int(that.form.pcShow);
          formData.isBottom = this.$util.boolean2Int(that.form.isBottom);
          formData.status = this.$util.boolean2Int(that.form.status);
          formData.parentId = formData.parentId.pop();
          if(formData.roles){
            formData.roles=formData.roles.join(",");
          }
          that.loading = true;
          that.loadingTip = "保存中...";
          if (that.infos.id != 0) {
            formData.id = that.infos.id;
          }
          //添加
          category
            .saveOrUpdate(formData)
            .then((res) => {
              if (res.errorCode == that.$commons.RespCode.success) {
                that.$message.success(res.errorMsg);
                that.$emit("callbackMethod");
              } else {
                that.$message.error(res.errorMsg);
              }
              that.loading = false;
            })
            .catch(() => {
              that.loading = false;
            });
        } else {
          return false;
        }
      });
    },
    cancelClick() {
      that.$emit("callbackMethod");
    },
    handleCancel() {
      that.previewVisible = false;
    },
    handlePreview(file) {
      that.previewImage = file.url || file.thumbUrl;
      that.previewVisible = true;
    },
    beforeUpload(file) {
      let isJPGPNG = false;
      if (file.type === "image/jpeg" || file.type === "image/png") {
        isJPGPNG = true;
      } else {
        isJPGPNG = false;
        that.$message.error("只能上传jpg/png格式的文件!");
      }
      let isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        that.$message.error("文件不能超过2MB!");
      }
      return isJPGPNG && isLt2M;
    },
    handlePictureChange(fileInfo) {
      that.pictureList = fileInfo.fileList;
      if (fileInfo.file.status == "done") {
        if (
          fileInfo.file.response.errorCode != that.$commons.RespCode.success
        ) {
          that.$message.error("上传失败");
        } else {
          that.form.picture = fileInfo.file.response.data.path;
        }
      } else if (fileInfo.file.status == "error") {
        that.$message.error("上传失败");
      } else if (fileInfo.file.status == "removed") {
        that.form.picture = "";
      }
    },
    handleH5PictureChange(fileInfo) {
      that.h5PictureList = fileInfo.fileList;
      if (fileInfo.file.status == "done") {
        if (
          fileInfo.file.response.errorCode != that.$commons.RespCode.success
        ) {
          that.$message.error("上传失败");
        } else {
          that.form.h5Picture = fileInfo.file.response.data.path;
        }
      } else if (fileInfo.file.status == "error") {
        that.$message.error("上传失败");
      } else if (fileInfo.file.status == "removed") {
        that.form.h5Picture = "";
      }
    },
    handleAdvImgChange(fileInfo) {
      that.advImgList = fileInfo.fileList;
      if (fileInfo.file.status == "done") {
        if (
          fileInfo.file.response.errorCode != that.$commons.RespCode.success
        ) {
          that.$message.error("上传失败");
        } else {
          that.form.advImg = fileInfo.file.response.data.path;
        }
      } else if (fileInfo.file.status == "error") {
        that.$message.error("上传失败");
      } else if (fileInfo.file.status == "removed") {
        that.form.advImg = "";
      }
    },
  },
};
</script>
<style>
</style>